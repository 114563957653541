<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-account-multiple</v-icon
        >
        <v-card-title> {{ computedPageHeader }} </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 ml-3 mr-2">
        <v-col cols="12" sm="12" md="3">
          <!-- <h2 class="align-text-left mb-3">
            Automate group management to make your life a litte easier
          </h2>
          <v-divider></v-divider> -->
          <div
            class="mb-4 mt-2 align-text-right d-flex flex-column align-end justify-center"
          >
            <div class="d-flex d-sm-none mb-3 align-self-center">
              <div class="people-button">
                <v-btn
                  v-if="permissions.includes('vue:read:people')"
                  text
                  color="primary"
                  class="mr-2"
                  to="/people"
                  active-class="no-active"
                  >People</v-btn
                >
              </div>
              <v-btn
                text
                color="primary"
                to="/people/groups"
                active-class="no-active"
                >Groups</v-btn
              >
            </div>
            <v-autocomplete
              outlined
              dense
              class="pa-1 mb-2 full-width"
              hide-details
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              v-if="userProfile.clientId === 1 && clients.length > 0"
            ></v-autocomplete>
            <div class="d-flex align-center">
              <span class="mr-3 mb-1" v-if="table.selected.length === 1"
                >{{ table.selected.length }} Group Selected</span
              >
              <span class="mr-3 mb-1" v-else
                >{{ table.selected.length }} Groups Selected</span
              >
              <v-btn
                v-if="table.selected.length"
                @click="clearSelected"
                rounded
                depressed
                small
                class="ml-1 mt-n1"
                >clear</v-btn
              >
            </div>
            <v-btn
              text
              small
              color="primary"
              @click="loadComplexGroupForm"
              v-if="
                permissions.includes('groups:create:group') &&
                  permissions.includes('rules:create:rule') &&
                  !isMobile
              "
              >New complex group</v-btn
            >
            <v-btn
              v-if="permissions.includes('groups:create:group')"
              text
              small
              color="primary"
              @click="showCSVWidget = true"
              >Upload a file to create a group</v-btn
            >
            <v-btn
              v-if="
                permissions.includes('messages:create:message') && !isMobile
              "
              :disabled="!table.selected.length"
              text
              small
              color="primary"
              @click="loadCommunicationsDialog"
              >Email these groups</v-btn
            >
            <!-- <v-btn :disabled="selected.length === 0" text small color="primary"
              >Manage group integrations</v-btn
            > -->
            <!-- <v-btn :disabled="selected.length === 0" text small color="primary"
              >Combine to create new group</v-btn
            > -->
            <v-btn
              v-if="permissions.includes('groups:delete:group')"
              :disabled="!table.selected.length"
              text
              small
              color="primary"
              @click="dialogDeleteMessage = true"
              >Delete selected groups</v-btn
            >
          </div>
          <v-divider></v-divider>
          <v-card
            rounded="0"
            elevation="2"
            class="mt-4"
            color="brandPaleBlue"
            v-show="!postItArray.includes('groups-1')"
          >
            <v-card-title class="d-flex justify-space-between">
              <v-icon color="grey">mdi-lightbulb-outline</v-icon>
              <v-btn icon
                ><v-icon
                  color="grey"
                  @click="$store.dispatch('setPostItArray', 'groups-1')"
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-card-title>
            <v-card-text class="align-text-left">
              <span
                >Groups are a simple but powerful tool to
                <b>increase program performance by as much as 40%.</b>
              </span>
              <br />
              <br />
              <span
                >Learn more about the different types of groups you can create
                in the
              </span>
              <a @click="openFAQ">Whistle FAQ</a>
              <!-- <span class="selectable-blue-text">Whistle FAQ</span> -->
              <!-- - <span class="selectable-blue-text">List Groups</span>
              
              - <span class="selectable-blue-text">Simple Groups</span>
             
              - <span class="selectable-blue-text">Complex Groups</span> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <div
            class="d-flex align-center"
            :class="isMobile ? '' : ' justify-space-between mr-2'"
          >
            <div class="d-none d-sm-flex mb-3 ">
              <div class="people-button">
                <v-btn
                  v-if="permissions.includes('vue:read:people')"
                  text
                  color="primary"
                  class="mr-2"
                  to="/people"
                  active-class="no-active"
                  >People</v-btn
                >
              </div>
              <v-btn
                text
                color="primary"
                to="/people/groups"
                active-class="no-active"
                >Groups</v-btn
              >
            </div>
            <v-text-field
              outlined
              placeholder="Search"
              :prepend-icon="isMobile ? '' : 'mdi-magnify'"
              clearable
              single-line
              dense
              hide-details
              v-model="table.debounceSearch"
              class="shrink mb-3"
            ></v-text-field>
            <v-btn
              v-if="permissions.includes('groups:create:group')"
              color="brandCyan"
              class="white--text mr-3 mb-3 ml-3"
              depressed
              width="145"
              @click="displayGroupTypeSelector()"
              >Add group</v-btn
            >
          </div>
          <div>
            <v-data-table
              v-model="table.selected"
              :headers="groupColumns"
              :items="visibleGroups"
              item-key="groupId"
              show-select
              :server-items-length="table.total"
              :options.sync="table.options"
              :items-per-page="table.itemsPerPage"
              :page.sync="table.page"
              class="elevation-0 group-table"
              :class="{
                'cursor-pointer': permissions.includes('groups:create:group')
              }"
              :loading="table.loading"
              loading-text="Loading... Please wait"
              no-data-text="No groups found"
              no-results-text="No groups found in search"
              @click:row="editGroup"
              :footer-props="table.footerProps"
              must-sort
              :sort-desc="true"
              sort-by="groupId"
            >
              <template v-slot:loading>
                <!-- {{ item }} -->
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="my-8"
                  :size="50"
                  :width="4"
                ></v-progress-circular>
              </template>
              <template v-slot:[`item.groupDisplayName`]="{ item }">
                <span
                  class="word-break"
                  :class="{
                    'selectable-blue-text': permissions.includes(
                      'groups:create:group'
                    )
                  }"
                  >{{ item ? item.groupDisplayName : "" }}</span
                >
              </template>
              <template v-slot:[`item.numInGroup`]="{ item }">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">mdi-account-multiple</v-icon>
                  <span class="mr-5">{{
                    !item
                      ? "-"
                      : item.numUsers !== undefined
                      ? item.numUsers
                      : "-"
                  }}</span>
                </div>
              </template>
              <template v-slot:[`item.qr`]="{ item }">
                <v-btn
                  icon
                  small
                  color="brandCyan"
                  v-if="showQrCode(item.groupType, item.clientId)"
                  @click.stop.native="loadQrCode(item.groupId)"
                  ><v-icon>mdi-qrcode</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialogs -->
    <!-- <v-dialog
      v-model="showCreateSimpleGroup"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    > -->
    <v-navigation-drawer
      v-model="showGroupTypeSelector"
      temporary
      fixed
      right
      width="500"
    >
      <GroupTypeSelector
        source="groups"
        :version="3"
        :clientId="clientId"
        @close="closeGroupCreator"
        @get-groups="getGroups(true)"
        @create-simple-group="displayCreateSimpleGroupForm"
        @create-qr-group="displayCreateQrGroupForm"
      ></GroupTypeSelector>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showCreateQrGroup"
      temporary
      fixed
      right
      width="500"
    >
      <QRGroupCreatorWidget
        source="groups"
        :version="3"
        :clientId="clientId"
        @close="closeGroupCreator"
        @get-groups="getGroups(true)"
      ></QRGroupCreatorWidget>
    </v-navigation-drawer>

     <v-navigation-drawer
      v-model="showCreateSimpleGroup"
      temporary
      fixed
      right
      width="500"
    >
      <GroupCreatorWidget
        source="groups"
        :version="3"
        :clientId="clientId"
        @close="closeGroupCreator"
        @get-groups="getGroups(true)"
      ></GroupCreatorWidget>
    </v-navigation-drawer>

    <QRViewer
      v-if="qr.dialog"
      :groupId="qr.groupId"
      :clientId="qr.clientId"
      @close="
        (qr.dialog = false),
          (qr.groupId = null),
          (qr.clientId = null),
          (qr.error = null)
      "
    />
    <!-- <v-dialog v-model="qr.dialog" :width="500">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center mb-4">
          <v-card-title class="word-break px-0 mx-0"
            >Group QR Code</v-card-title
          >
        </div>
        <qrcode-vue v-if="qr.url" :value="qr.url" :size="250" level="H" />
        <p v-else-if="qr.error" class="red--text">{{ qr.error }}</p>
        <span v-if="qr.url">{{ qr.url }}</span>

        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="(qr.dialog = false), (qr.url = null), (qr.error = null)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- </v-dialog> -->
    <!-- <v-dialog v-model="showCreateListGroup" width="450" persistent>
      <v-card class="pb-3">
        <v-card-title class="dialog-header" color="primary">
          Upload New List Group
        </v-card-title>
        <v-divider />
        <v-card-text v-if="!createListGroupSuccess">
          <v-container fluid>
            <v-row align="center">
              <v-col class="d-flex flex-column align-center" cols="12" sm="12">
                <p>
                  Upload your CSV including group names, group display names,
                  and email addresses tied to peoples' accounts.
                </p>
                <v-btn
                  color="primary"
                  width="250"
                  class="my-3"
                  @click="downloadTemplate"
                >
                  Download Template</v-btn
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col class="d-flex flex-column pb-0" cols="12" sm="12">
                <v-file-input
                  outlined
                  label="Select your CSV"
                  class="fileInput px-3 pt-3"
                  accept=".csv"
                  v-model="file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="createListGroupSuccess">
          <v-container fluid>
            <v-row
              class="d-flex justify-center flex-column mt-3"
              v-if="listGroupResultsSuccess.length > 0"
            >
              <h2>
                {{ listGroupResultsSuccess.length }} group<span
                  v-if="listGroupResultsSuccess.length != 1"
                  >s</span
                >
                were successfully created!
              </h2>
              <span
                v-for="group in listGroupResultsSuccess"
                :key="group.groupRule.groupId"
              >
                Group Name: {{ group.groupRule.groupName }}, Members:
                {{ group.groupRule.GroupAssignments.length }}
              </span>
            </v-row>
            <v-row
              class="d-flex justify-center flex-column mt-5"
              v-if="listGroupResultsErrors.length > 0"
            >
              <h2>
                Something went wrong with
                {{ listGroupResultsErrors.length }} group<span
                  v-if="listGroupResultsErrors.length != 1"
                  >s</span
                >
              </h2>
              <span
                v-for="error in listGroupResultsErrors"
                :key="error.message"
                class="mt-1"
              >
                {{ error.message }}
              </span>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="!createListGroupSuccess">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clearListGroupForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!file"
            @click="createListGroup"
          >
            Upload
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="createListGroupSuccess">
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="clearListGroupForm">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="complexGroupForm.dialogCreator"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card rounded="0">
        <v-toolbar dark color="primary" rounded="0">
          <v-toolbar-title>Create a New Complex Group</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="white" text @click="resetComplexGroupForm"
              >Close
              <v-icon color="white" class="ml-2">mdi-close</v-icon></v-btn
            >
            <v-btn
              color="white"
              text
              dark
              @click="createComplexGroup"
              :disabled="
                !complexGroupForm.valid ||
                  complexGroup.rules === null ||
                  !validateRules
              "
              >Create
              <v-icon color="white" class="ml-2"
                >mdi-content-save</v-icon
              ></v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form ref="complexGroupForm" v-model="complexGroupForm.valid">
            <!-- <v-text-field
              outlined
              class="pa-2 mt-3"
              v-model="complexGroup.groupName"
              :rules="[(v) => !!v || 'Group name is required']"
              label="Group name*"
              required
            ></v-text-field> -->
            <v-text-field
              outlined
              class="pa-2"
              v-model="complexGroup.groupDisplayName"
              :rules="[
                v => !!v || 'Group name is required',
                v => !!(v && v.length < 255) || 'Group name should be shorter'
              ]"
              label="Group name*"
            ></v-text-field>
            <v-switch
              class="pa-2"
              v-if="hasSubClients"
              v-model="complexGroup.includeSubClients"
              :label="`Include Sub-Clients`"
              hide-details
            ></v-switch>
            <p class="text-left pl-2">
              Note: Complex groups don't support external users
            </p>
            <!-- <v-switch
              class="pl-2"
              v-if="userProfile && userProfile.clientId == 1"
              v-model="complexGroupForm.optimizePerformance"
              @change="subClientFlagChanged"
              label="Optimize for performance"
            ></v-switch> -->
            <div class="d-flex align-center mb-3">
              <v-divider />
              <h3 class="mx-5">Group Rule Creator</h3>
              <v-divider />
            </div>
            <div class="py-4 px-10 d-flex justify-space-between mr-3">
              <RuleBuilder
                @close-dialog="closeRuleBuilder"
                @refresh-estimate="refreshComplexGroupEstimate"
                :useMetrics="true"
                :useUserAttributes="true"
                :showSaveButton="false"
                :showExitButton="false"
                context="Groups"
                :trigger="complexGroupForm.ruleBuilderTrigger"
                :rules="complexGroup.rules"
                :metrics="metrics"
                :modules="modules"
                :name="complexGroup.groupDisplayName"
                :description="complexGroup.groupDisplayName"
                @update-rules="onRulesValueChanged"
                @update-name="onNameValueChanged"
                @update-description="onDescriptionValueChanged"
                :estimatedParticipants="complexGroup.estimatedParticipants"
                :loadingEstimatedParticipants="
                  complexGroup.loadingEstimatedParticipants
                "
                :disableEstimatedParticipants="
                  complexGroup.disableEstimatedParticipants
                "
                :clientId="clientId"
                :includeSubClients="complexGroup.includeSubClients"
                :readOnly="!complexGroup.showSaveButton"
                :hideTextFields="true"
              ></RuleBuilder>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="pr-16 pb-4">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetComplexGroupForm">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            @click="createComplexGroup"
            :disabled="
              !complexGroupForm.valid ||
                complexGroup.rules === null ||
                !validateRules
            "
          >
            <span class="white--text">Create</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Drawer used for viewing an existing group -->
    <v-navigation-drawer
      v-model="showEditGroup"
      temporary
      fixed
      right
      :stateless="!!qr.dialog"
      width="500"
    >
      <!-- <v-card-title
        class="dialog-header d-flex align-center justify-space-between"
        color="primary"
        v-if="!groupEditForm.showGroupAssignmentPage"
      >
        <span>
          Editing Group
        </span>
        <div>
          <v-btn color="white" text @click="clearEditGroupForm">
            Cancel
          </v-btn>
          <v-btn
            color="white"
            text
            dark
            :disabled="!groupEditForm.valid"
            @click="updateGroup"
          >
            Update
          </v-btn>
        </div> </v-card-title
      ><v-card-title
        class="dialog-header d-flex align-center justify-space-between"
        color="primary"
        v-else
      >
        <span>Select people to add</span>
      </v-card-title>
      <v-divider /> -->
      <v-toolbar dark color="primary" rounded="0">
        <v-btn
          dark
          icon
          @click="clearEditGroupForm"
          :class="groupEditForm.showGroupAssignmentPage ? 'hidden' : null"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{
          groupEditForm.showGroupAssignmentPage ? "Add people" : "Editing Group"
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-toolbar-items
          :class="groupEditForm.showGroupAssignmentPage ? 'hidden' : null"
        >
          <v-btn dark text @click="clearEditGroupForm">
            <v-icon>mdi-save</v-icon>
          </v-btn>
        </v-toolbar-items> -->
        <v-btn
          dark
          icon
          :disabled="!groupEditForm.valid"
          @click="updateGroup"
          :class="groupEditForm.showGroupAssignmentPage ? 'hidden' : null"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-if="!groupEditForm.showGroupAssignmentPage && showEditGroup"
      >
        <v-form ref="groupEditForm" v-model="groupEditForm.valid">
          <!-- <v-text-field
            outlined
            class="pa-1 mt-5"
            v-model="editingGroup.groupName"
            :rules="[(v) => !!v || 'Group name is required']"
            label="Name*"
            required
          ></v-text-field> -->
          <v-text-field
            outlined
            class="pa-1"
            v-model="editingGroup.groupDisplayName"
            :rules="[
              v => !!v || 'Group name is required',
              v => !!(v && v.length < 255) || 'Group name should be shorter'
            ]"
            label="Group name*"
          ></v-text-field>
          <v-text-field
            outlined
            v-if="
              editingGroup.groupType !== 'List' &&
                editingGroup.groupType !== 'Complex'
            "
            class="pa-1"
            v-model="editingGroup.enrollmentRules"
            :readonly="true"
            label="Group Rules"
          ></v-text-field>
          <v-text-field
            outlined
            v-if="
              editingGroup.groupType == 'List' && editingGroup.hierarchyRules
            "
            class="pa-1"
            v-model="editingGroup.hierarchyRules"
            :readonly="userProfile.clientId == 1 ? false : true"
            label="Special Rules"
          ></v-text-field>
          <!-- <v-btn
            color="primary"
            v-if="editingGroup.rules != null"
            @click="
              loadRuleBuilder(
                editingGroup.rules,
                editingGroup.ruleName,
                editingGroup.ruleDescription
              )
            "
            class="my-2"
            >View Rules</v-btn
          > -->
          <v-checkbox
            v-if="editingGroup.includeSubClients"
            disabled
            label="Includes sub-clients"
            :input-value="true"
            color="brandCyan"
            class="pa-1"
            dense
          />
          <v-checkbox
            disabled
            label="External users allowed"
            :input-value="!editingGroup.includeSubClients"
            color="brandCyan"
            class="pa-1"
            dense
          />
          <p class="text--caption" v-if="editingGroup.rules != null">
            Note: Rules for a complex group cannot be edited at this time.
            Please make a new group.
          </p>
          <v-card-text v-if="editingGroup.groupType !== 'List'"
            >This is a dynamic group and its members cannot be
            edited.</v-card-text
          >
          <v-btn
            text
            color="primary"
            class="ma-3 d-flex justify-start"
            v-if="showQrCode(editingGroup.groupType, editingGroup.clientId)"
            @click="loadQrCode(editingGroup.groupId)"
          >
            <v-icon left>mdi-qrcode</v-icon>View QR Code</v-btn
          >
          <v-btn
            text
            color="primary"
            class="ma-3 d-flex justify-start"
            v-if="editingGroup.groupType === 'List'"
            @click="loadUserGroupAssignmentPage"
          >
            <v-icon left>mdi-account-group-outline</v-icon>Add people to
            group</v-btn
          >
          <v-btn
            text
            :loading="editingGroup.loadingGroupRefresh"
            color="primary"
            class="ma-3 d-flex justify-start"
            v-if="['Complex', 'Simple'].includes(editingGroup.groupType)"
            @click="refreshGroup(editingGroup.groupId, editingGroup.clientId)"
          >
            <v-icon left>mdi-refresh</v-icon>Refresh group</v-btn
          >
          <!-- {{ editingGroup.groupAssignments.length }} -->
          <v-data-table
            :headers="
              editingGroup && editingGroup.groupType != 'List'
                ? groupEditForm.columns.slice(1)
                : groupEditForm.columns
            "
            :items="editingGroup.GroupAssignments"
            item-key="userId"
            class="group-table"
            :loading="editingGroup.loadingUsers"
            loading-text="Loading... Please wait"
            no-data-text="No people in group"
            :items-per-page="editingGroup.userTable.itemsPerPage"
          >
            <template v-slot:[`item.delete`]="{ item }">
              <div v-if="editingGroup.groupType === 'List'">
                <v-btn
                  icon
                  color="red"
                  @click="deleteGroupAssignment(item.groupAssignmentId)"
                >
                  <v-icon>mdi-close-box-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span class="word-break">{{
                item.User.firstName + " " + item.User.lastName
              }}</span>
            </template>
            <template v-slot:[`item.displayName`]="{ item }">
              <span class="word-break">{{ item.displayName }}</span>
            </template>
            <template v-slot:[`item.businessEmail`]="{ item }">
              {{
                item.User
                  ? item.User.businessEmail || item.User.businessPhone
                  : ""
              }}
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>

      <v-card-text
        class="mt-3 px-7 d-flex flex-column"
        v-else-if="showEditGroup"
      >
        <v-btn
          outlined
          width="120"
          :color="'primary'"
          @click="clearUserGroupForm"
          ><v-icon class="mr-1">mdi-chevron-left</v-icon>Back</v-btn
        >
        <v-text-field
          v-model="userTable.debounceSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
          dense
          class="mt-6 mb-3"
        ></v-text-field>
        <UserTable
          ref="user-table"
          :selected="editingGroup.GroupAssignments"
          :search="userTable.search"
          :displayEmail="true"
          @select-item="addUserToGroupAssignment($event)"
        />
        <!-- <v-data-table
          :headers="userTable.columns"
          :items="visibleUsers"
          item-key="userId"
          class="group-table my-2"
          :loading="userTable.loading"
          :search="userTable.search"
          :options.sync="userTable.options"
          :server-items-length="userTable.total"
          :items-per-page="userTable.itemsPerPage"
          :footer-props="userTable.footerProps"
          no-data-text="No people found"
        >
          <template v-slot:[`item.add`]="{ item }">
            <v-btn
              icon
              color="primary"
              @click="addUserToGroupAssignment(item)"
              v-if="
                editingGroup &&
                  editingGroup.GroupAssignments &&
                  !editingGroup.GroupAssignments.find(
                    x => x.userId == item.userId
                  )
              "
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              @click="removeUserFromGroupAssignment(item.userId)"
              v-else
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.businessEmail`]="{ item }">
            {{ item.businessEmail || item.businessPhone }}
          </template>
          <template v-slot:[`item.displayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>

        </v-data-table> -->
      </v-card-text>
      <v-card-actions v-if="!groupEditForm.showGroupAssignmentPage">
        <v-btn color="red" text @click="deleteGroup"> Delete </v-btn>
      </v-card-actions>
      <v-card-actions class="py-2" v-else> </v-card-actions>
    </v-navigation-drawer>
    <!-- Dialog for rule builder -->
    <!-- <v-dialog
      v-model="complexGroupForm.dialogRuleBuilder"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card rounded="0" width="100%">
        <div class="py-4 d-flex justify-space-between mr-3">
          <RuleBuilder
            @close-dialog="closeRuleBuilder"
            @refresh-estimate="refreshComplexGroupEstimate"
            :useMetrics="true"
            :useUserAttributes="true"
            :showSaveButton="complexGroup.showSaveButton"
            :showExitButton="true"
            context="Groups"
            :trigger="complexGroupForm.ruleBuilderTrigger"
            :rules="complexGroup.rules"
            :metrics="metrics"
            :modules="modules"
            :name="complexGroup.ruleName"
            :description="complexGroup.ruleDescription"
            @update-rules="onRulesValueChanged"
            @update-name="onNameValueChanged"
            @update-description="onDescriptionValueChanged"
            :estimatedParticipants="complexGroup.estimatedParticipants"
            :loadingEstimatedParticipants="
              complexGroup.loadingEstimatedParticipants
            "
            :disableEstimatedParticipants="
              complexGroup.disableEstimatedParticipants
            "
            :clientId="clientId"
            :includeSubClients="complexGroup.includeSubClients"
            :readOnly="!complexGroup.showSaveButton"
          ></RuleBuilder>
        </div>
      </v-card>
    </v-dialog> -->
    <!-- Confirm delete dialog -->
    <v-dialog v-model="dialogDeleteMessage" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to delete the selected groups?
          </v-card-title>
        </div>

        <v-card-actions
          class="mx-12 d-flex justify-center"
          :class="isMobile ? 'flex-column' : ''"
        >
          <v-btn
            color="primary"
            @click="dialogDeleteMessage = false"
            outlined
            width="140"
            >Don't delete</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="deleteGroups"
            width="140"
            :class="isMobile ? 'mt-4' : ''"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete error dialog for programs -->
    <v-dialog v-model="dialogDeleteErrorMessage" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            We found programs attached to the following groups
          </v-card-title>
        </div>
        <p
          v-for="error in dialogDeleteErrorArray"
          :key="`${error.programId}-${error.groupId}`"
          class="mb-2"
        >
          Program '<span class="font-weight-bold">{{ error.displayName }}</span
          >' uses group '{{ getGroupName(error.groupId) }}'
        </p>

        <v-card-actions class="mx-auto">
          <v-btn
            color="primary"
            depressed
            @click="dialogDeleteErrorMessage = false"
            width="140"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying message creator. -->
    <v-dialog
      v-model="dialogMessageCreator"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <MessageCreator
        v-if="dialogMessageCreator"
        @close-dialog="closeCommunicationsDialog"
        :trigger="communicationTrigger"
        peopleOrGroups="groups"
        :largeVersion="true"
        :preSelectedAudience="table.selected"
      ></MessageCreator>
    </v-dialog>
    <!-- CSV Widget -->
    <v-navigation-drawer
      v-model="showCSVWidget"
      temporary
      fixed
      right
      width="500"
    >
      <GroupCSVWidget
        v-if="showCSVWidget"
        :clientId="clientId"
        @close="showCSVWidget = false"
        @upload-file="createListGroupV2(file, groupName)"
        @get-groups="getGroups(true)"
      ></GroupCSVWidget>
    </v-navigation-drawer>

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import GroupService from "@/services/GroupService";
import RulesService from "@/services/RulesService";
import ProgramService from "@/services/ProgramService";
import LearningService from "@/services/LearningService";
import UserService from "@/services/UserService";

import LoadingDialog from "@/components/loadingDialog.vue";
// import SimpleGroupCreator from "@/components/SimpleGroupCreator.vue";
import GroupCreatorWidget from "@/components/groups/GroupCreatorWidgetSimplified";
import QRGroupCreatorWidget from "@/components/groups/QRGroupCreatorWidget";
import GroupTypeSelector from "@/components/groups/GroupTypeSelector.vue";
import RuleBuilder from "@/components/RuleBuilderV3.vue";
import MessageCreator from "@/components/MessageCreator.vue";
import GroupCSVWidget from "@/components/groups/GroupCSVWidget.vue";
import UserTable from "@/components/data-table/Users.vue";
import QRViewer from "@/components/QR.vue";

import { debounce } from "@/shared_data/functions";
import { mapState } from "vuex";

// import QrcodeVue from "qrcode.vue";

export default {
  name: "Groups",
  title: "Whistle | Groups",
  components: {
    LoadingDialog,
    // SimpleGroupCreator,
    GroupCreatorWidget,
    QRGroupCreatorWidget,
    GroupTypeSelector,
    RuleBuilder,
    MessageCreator,
    GroupCSVWidget,
    UserTable,
    // QrcodeVue,
    QRViewer
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingTable: true,
      showGroupTypeSelector: false,
      showCreateSimpleGroup: false,
      showCreateQrGroup: false,
      dialogMessageCreator: false,
      communicationTrigger: false,
      hasSubClients: false,
      // selected: [],
      // groups: [],
      loadingUsers: false,
      users: [],
      // allUsers: [],
      // allGroups: [],
      // groupTableSearch: null,
      columns: [
        {
          text: "Group ID",
          align: "start",
          sortable: true,
          value: "groupId",
          width: "10%"
        },
        { text: "Group", value: "groupDisplayName", width: "45%" },
        { text: "Group Type", value: "groupType", width: "15%" },
        // { text: "Rules", value: "enrollmentRules", width: "25%" },
        {
          text: "Participants",
          value: "numInGroup",
          width: "15%",
          // align: "end",
          sortable: false
        },
        { text: "", value: "qr", width: "15%" }
      ],

      showCreateListGroup: false,
      createListGroupSuccess: false,
      listGroupResultsSuccess: [],
      listGroupResultsErrors: [],
      showEditGroup: false,
      showGroupAssignmentUsers: false,
      file: null,
      groupEditForm: {
        valid: false,
        loadingTable: false,
        tablePage: 1,
        columns: [
          { text: "", value: "delete" },
          { text: "Name", value: "displayName", width: "40%" },
          { text: "Email / Phone", value: "businessEmail", width: "40%" }
        ],

        loadingUserTable: false,
        showGroupAssignmentPage: false
      },
      editingGroup: {
        clientId: null,
        groupId: null,
        groupName: null,
        groupDisplayName: null,
        groupType: null,
        enrollmentRules: null,
        rules: null,
        ruleName: null,
        ruleDescription: null,
        hierarchyRules: null,
        GroupAssignments: [],
        loadingUsers: false,
        loadingGroupRefresh: false,
        assignedUsers: [],
        userTable: {
          itemsPerPage: 10,
          page: 1,
          // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
          awaitingPage: 1,
          loading: false,
          users: [],
          nextPage: [],
          total: 0,
          selected: [],
          options: {},
          footerProps: {
            "items-per-page-options": [10, 25, 50, 100]
          }
        }
      },
      complexGroupForm: {
        valid: false,
        dialogRuleBuilder: false,
        ruleBuilderTrigger: false,
        dialogCreator: false,
        optimizePerformance: false
      },
      complexGroup: {
        clientId: null,
        groupName: null,
        groupDisplayName: null,
        rules: null,
        ruleName: null,
        ruleDescription: null,
        estimatedParticipants: null,
        loadingEstimatedParticipants: false,
        disableEstimatedParticipants: false,
        showSaveButton: true,
        includeSubClients: false,
        estimatedParticipantsResponseId: null
      },
      ruleGroups: [],
      metrics: [],
      modules: [],
      dialogDeleteMessage: false,
      dialogDeleteErrorMessage: false,
      dialogDeleteErrorArray: [],
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        groups: [],
        nextPage: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      },
      userTable: {
        search: null,
        debounceSearch: null
      },
      showCSVWidget: false,
      qr: {
        url: null,
        dialog: false,
        error: null,
        groupId: null,
        clientId: null
      }
    };
  },

  created() {
    // console.log("group created");
    if (this.$auth) {
      // this.clientId = this.userProfile.clientId;
      // this.getRuleGroups();
      this.getLearningModules();
      this.getMetrics();

      UserService.getClientsByClient(this.clientId)
        .then(response => {
          if (response.length > 0) {
            this.hasSubClients = true;
          }
        })
        .catch(error => {
          console.log("Error getting subclients", error.response || error);
        });
    }

    // this.unwatch = this.$store.watch(
    //   (state) => {
    //     return state.loadItemFromSearch; // could also put a Getter here
    //   },
    //   (groupId) => {
    //     console.log(`User clicked item in search bar`);
    //     console.log(groupId);
    //     if (
    //       groupId !== null &&
    //       this.permissions.includes("groups:create:group")
    //     ) {
    //       this.editGroup(groupId);
    //       //We wipe the ID storede in the store after we load it up
    //       this.$store.dispatch("setLoadItemFromSearch", null);
    //     }
    //   },
    //   //Optional Deep if you need it
    //   {
    //     deep: true,
    //   }
    // );
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  // beforeDestroy() {
  //   //Clears the search in case they go to a page without search
  //   this.$store.dispatch("setSearchArray", []);
  //   this.unwatch();
  // },
  methods: {
    async getGroups(reset = false, groupId = null) {
      try {
        this.table.loading = true;
        if (reset) {
          this.table.options.page = 1;
        }
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        let options = this.table.options;
        let page = this.table.options.page;
        let search = this.table.search
          ? this.table.search.replace(/'/g, "\\'")
          : null;

        let filter = `status != 'Deleted' && clientId=${this.clientId}`;
        if (groupId) filter += ` && groupId = ${groupId}`;
        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (groupDisplayName.like('%${search}%') || groupType.like('%${search}%') || groupId = '${search}') `;
        }
        // if first page, fetch double
        let limit = groupId ? 1 : options.itemsPerPage; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = reset || groupId ? 0 : (page - 1) * limit;
        var sort = "groupId DESC";
        if (options.sortBy && options.sortBy.length) {
          let col = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";
          // if (col == "description") col = "startDate";
          sort = `${col} ${type}`;
        }
        // let extract =
        //   "clientId,groupId,groupDisplayName,groupType,enrollmentRules";
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let response = await GroupService.getGroupsV2({
          filter: filter,
          limit: limit,
          offset: offset,
          sort: sort,
          // extract,
          expand: "null",
          count: 1
        });

        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort
        ) {
          console.log("Ignoring response");
          return;
        }

        console.log("Group response ", response);
        let rows = response.result.rows;

        if (!groupId) this.table.total = response.result.count;

        // We instantiate the users array with an empty array to match the length of the results
        if (reset)
          this.table.groups = new Array(this.table.total).fill(undefined);

        //Prune the returned users for the data we need for the table
        rows = rows.map(function(group) {
          group.displayName = group.name = group.groupDisplayName;

          group.id = group.groupId;

          // group.numUsers = (group.GroupAssignments || []).length;
          group.GroupAssignments = [];
          return group;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        if (groupId !== null) {
          let existingGroup = this.table.groups.findIndex(
            x => x.groupId == groupId
          );
          if (existingGroup != -1) {
            this.$set(this.table.groups, existingGroup, rows[0]);
            // this.table.groups[existingGroup] = rows[0];
          } else {
            this.table.groups = this.table.groups.concat(rows);
          }
        } else {
          if (offset == 0)
            this.table.groups = rows.concat(
              this.table.groups.slice(rows.length)
            );
          else
            this.table.groups = this.table.groups
              .slice(0, offset)
              .concat(rows, this.table.groups.slice(offset + rows.length));
        }
        if (reset) this.selected = [];
      } catch (err) {
        console.log("error getting groups ", err);
      } finally {
        this.table.loading = false;
      }
    },
    displayGroupTypeSelector() {
      this.showGroupTypeSelector = true;
      this.showCreateQrGroup = false;
      this.showCreateSimpleGroup = false;
    },
    displayCreateSimpleGroupForm() {
      this.showCreateSimpleGroup = true;
      this.showCreateQrGroup = false;
      this.showGroupTypeSelector = false;
    },
    displayCreateQrGroupForm() {
      this.showCreateQrGroup = true;
      this.showCreateSimpleGroup = false;
      this.showGroupTypeSelector = false;
    },
    getRuleGroups() {
      RulesService.getRuleGroupsforGroups()
        .then(response => {
          console.log("Rule Groups for Groups: ", response);
          var rules = [];
          rules = response
            .filter(rule => rule.status !== "Deleted")
            .map(x => {
              var obj = x;
              obj.numRules = obj.rules.group.length;
              if (obj.name == null) {
                obj.name = "Rules for Group " + obj.groupName;
              }
              return obj;
            });

          this.ruleGroups = rules;
        })
        .catch(error => {
          console.log("Error fetching rule groups!", error.response);
        });
    },
    getMetrics() {
      ProgramService.getMetrics()
        .then(response => {
          console.log("Metrics: ", response);
          var metrics = response
            .filter(
              metric =>
                metric.status !== "Deleted" &&
                (metric.clientId == this.clientId || metric.clientId == 0)
            )
            .map(x => {
              var obj = x;
              obj.id = x.metricId.toString();
              obj.context = "MetricDefinitions";
              obj.contextResourceId = x.metricId.toString();
              obj.contextColumn = "metricValue";
              obj.label = x.metricName;
              obj.dataType = x.dataType;
              obj.showNumberOptions =
                x.calculateProgress && x.dataType !== "BOOLEAN";
              return obj;
            });
          this.metrics = metrics;
        })
        .catch(error => {
          console.log("Error!", error);
        });
    },
    getLearningModules() {
      LearningService.getModulesAdmin("learning", this.clientId)
        .then(response => {
          console.log("Learning Modules: ", response);
          var modules = response.modules
            .filter(
              module =>
                module.status !== "Deleted" && module.clientId == this.clientId
            )
            .map(x => {
              var obj = x;
              obj.id = x.learningModuleId.toString();
              obj.context = "LearningModules";
              obj.contextResourceId = x.learningModuleId.toString();
              obj.contextColumn = "metricValue";
              obj.label = "Module Progress: " + x.displayName.substring(0, 30);
              obj.dataType = "INTEGER";
              obj.showNumberOptions = true;
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.modules = modules;
        })
        .catch(error => {
          console.log("Error!", error);
        });
    },
    clearListGroupForm() {
      this.file = null;
      this.showCreateListGroup = false;
      this.showLoadingDialog = false;
      this.createListGroupSuccess = false;
      this.listGroupResultsErrors = [];
      this.listGroupResultsSuccess = [];
    },
    async getGroupAssignments(page, reset = false) {
      console.log("Reset ? ", reset);
      try {
        if (reset) page = 0;
        if (page == 0) this.editingGroup.loadingUsers = true;

        let filter = `status != 'Deleted' && groupId=${this.editingGroup.groupId}`;

        // This array is used to update a group, so it's not paginated
        // that way we have all users email addresses to map
        let limit = 100;
        let offset = page * limit;
        let expand = "User";
        let sort = "User.firstName ASC, User.lastName ASC";

        const groupAssignmentOptions = {
          limit: limit,
          sort: sort,
          filter: filter,
          offset: offset,
          expand: expand
        };

        console.log(groupAssignmentOptions);

        const response = await GroupService.getGroupAssignmentV2(
          groupAssignmentOptions
        );

        let rows = response.result.rows.map(user => {
          user.displayName = user.User
            ? user.User.firstName + " " + user.User.lastName
            : "";
          return user;
        });

        console.log("Got response from group assignment call ", rows);

        if (page == 0) {
          this.editingGroup.GroupAssignments = rows;
        } else {
          this.editingGroup.GroupAssignments = this.editingGroup.GroupAssignments.concat(
            rows
          );
        }

        if (
          Number(this.editingGroup.GroupAssignments.length) <
          Number(response.result.count)
        ) {
          console.log("GETTING MORE GROUP ASSIGNMENTS");
          page = page + 1;
          this.getGroupAssignments(page, false);
        } else {
          this.editingGroup.loadingUsers = false;
        }
        // this.editingGroup.loadingUsers = false;
      } catch (error) {
        console.log(error);
        this.editingGroup.loadingUsers = false;
      }
    },
    editGroup(groupId) {
      if (!this.permissions.includes("groups:create:group")) {
        return;
      }
      console.log("Editing group " + groupId);
      if (typeof groupId == "object") {
        var group = groupId;
      } else {
        group = this.table.groups.find(x => x.groupId === groupId);
      }

      this.editingGroup.loadingUsers = true;
      // console.log("clearing user table data");
      // Clear group assignments
      this.editingGroup.GroupAssignments = [];
      // Reset table data
      this.editingGroup.userTable.page = 1;
      // The page is not staying in sync with the page.sync value, reset it here
      this.editingGroup.userTable.options.page = 1;
      // TODO: Make sure it's ok to not reset items per page between table
      // this.editingGroup.userTable.itemsPerPage = 10;
      this.editingGroup.userTable.awaitingPage = 1;

      this.groupEditForm.showGroupAssignmentPage = false;
      // this.editingGroup.tablePage = 1;
      this.editingGroup.clientId = group.clientId;
      this.editingGroup.groupId = group.groupId;
      this.editingGroup.groupDisplayName = group.groupDisplayName;
      this.editingGroup.groupName = group.groupName;
      this.editingGroup.groupType = group.groupType;
      this.editingGroup.hierarchyRules = group.hierarchyRules;
      this.editingGroup.enrollmentRules = group.enrollmentRules;
      this.editingGroup.includeSubClients = group.includeSubClients;
      // this.editingGroup.GroupAssignments = group.GroupAssignments;
      // var users = JSON.parse(JSON.stringify(group.GroupAssignments));
      // users.forEach((x) => {
      //   x.User.displayName =
      //     x.User.displayName || x.User.firstName + " " + x.User.lastName;
      //   return x;
      // });
      // this.editingGroup.GroupAssignments = users;

      // If it's a complex group, we get the rules and store them
      if (group.groupType == "Complex") {
        var ruleGroup = this.ruleGroups.find(
          x => x.contextResourceId == group.groupId
        );

        this.editingGroup.rules = null;
        this.editingGroup.ruleName = null;
        this.editingGroup.ruleDescription = null;
        if (ruleGroup) {
          this.editingGroup.rules = ruleGroup.rules;
          this.editingGroup.ruleName = ruleGroup.name;
          this.editingGroup.ruleDescription = ruleGroup.description;
        }

        this.complexGroup.showSaveButton = false;
      } else {
        this.editingGroup.rules = null;
      }
      this.editingGroup.loadingGroupRefresh = false;
      this.showEditGroup = true;
      console.log(this.editingGroup);
      this.getGroupAssignments(0, true);
    },
    clearEditGroupForm() {
      this.showEditGroup = false;
      this.showLoadingDialog = false;
      this.editingGroup.GroupAssignments = [];
      this.editingGroup.hierarchyRules = null;
      this.editingGroup.enrollmentRules = null;
      this.editingGroup.groupType = null;
      this.editingGroup.rules = null;
      this.editingGroup.ruleName = null;
      this.editingGroup.ruleDescription = null;
      if (this.$refs.groupEditForm) this.$refs.groupEditForm.reset();
    },
    clearUserGroupForm() {
      this.groupEditForm.showGroupAssignmentPage = false;
      this.userTable.debounceSearch = null;
      this.userTable.search = null;
    },
    async deleteGroup() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting group";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      try {
        let response = await GroupService.deleteGroups(
          [this.editingGroup.groupId],
          this.clientId
        );
        console.log("Group deletion response ", response);
        this.getGroups(true);
        this.table.selected = [];
        this.dialogDeleteMessage = false;
      } catch (err) {
        console.log("Error deleting groups ", err);
        // Now we want to show the delete error screen with an explanation
        if (err.data && err.data.length) {
          this.dialogDeleteErrorMessage = true;
          this.dialogDeleteErrorArray = err.data;
        }
      } finally {
        this.clearEditGroupForm();
      }
    },
    async deleteGroups() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting groups";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      var array = this.table.selected.map(function(group) {
        return group.groupId;
      });
      console.log(array);

      try {
        let response = await GroupService.deleteGroups(array, this.clientId);
        console.log("Group deletion response ", response);
        this.getGroups(true);
        this.table.selected = [];
        this.dialogDeleteMessage = false;
      } catch (err) {
        console.log("Error deleting groups ", err);
        // Now we want to show the delete error screen with an explanation
        if (err.data && err.data.length) {
          this.dialogDeleteErrorMessage = true;
          this.dialogDeleteErrorArray = err.data;
        }
      } finally {
        this.clearEditGroupForm();
      }
    },
    // Used by group deletion error message to display what group is tied to a program
    getGroupName(groupId) {
      let matchingGroup = this.table.groups.find(
        x => x && x.groupId == groupId
      );
      if (matchingGroup) return matchingGroup.groupDisplayName;
      else return groupId;
    },
    async updateGroup() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating Group";
      this.loadingMessageLine1 = "This shouldn't take but a minute.";
      this.loadingMessageLine2 = "";

      var group = {
        groupId: this.editingGroup.groupId,
        groupName: this.editingGroup.groupDisplayName,
        groupDisplayName: this.editingGroup.groupDisplayName,
        groupType: this.editingGroup.groupType,
        updatedBy: this.userProfile.updatedBy
      };
      if (this.editingGroup.clientId !== null) {
        group.clientId = this.editingGroup.clientId;
      }
      if (this.editingGroup.hierarchyRules)
        group.hierarchyRules = this.editingGroup.hierarchyRules;
      console.log("Updating group", group);

      try {
        let response;
        if (group.groupType == "List") {
          if (!this.editingGroup.loadingUsers)
            group.userIds = this.editingGroup.GroupAssignments.map(
              x => x.userId
            );
          response = await GroupService.createGroupV2(group, "list");
        } else {
          response = await GroupService.updateGroup(
            this.editingGroup.groupId,
            group
          );
        }
        console.log(response);
        this.clearEditGroupForm();
        this.getGroups(false, this.editingGroup.groupId);
      } catch (err) {
        console.log("Error updating group ", err);
      } finally {
        this.showLoadingDialog = false;
      }
    },
    deleteGroupAssignment(groupAssignmentId) {
      console.log("Deleting assignment " + groupAssignmentId);
      //Updates local array of group assignments
      var groupAssignmentIndex = this.editingGroup.GroupAssignments.findIndex(
        x => x.groupAssignmentId === groupAssignmentId
      );
      this.editingGroup.GroupAssignments.splice(groupAssignmentIndex, 1);

      var groupIndex = this.table.groups.findIndex(
        x => x.groupId === this.editingGroup.groupId
      );
      console.log(groupIndex);
    },
    loadUserGroupAssignmentPage() {
      this.groupEditForm.loadingUserTable = true;
      this.groupEditForm.showGroupAssignmentPage = true;
      this.groupEditForm.loadingUserTable = false;
      // if (this.userTable.options) this.userTable.options.page = 1;
    },
    addUserToGroupAssignment(user) {
      console.log("Adding user: ", user);
      if (
        !this.editingGroup.GroupAssignments.find(x => x.userId == user.userId)
      ) {
        this.editingGroup.GroupAssignments.push({
          groupAssignmentId: new Date().getTime(),
          userId: user.userId,
          displayName: user.firstName + " " + user.lastName,
          User: user
        });
      } else this.removeUserFromGroupAssignment(user.userId || user);
    },
    removeUserFromGroupAssignment(user) {
      console.log("Removing user: ", user);
      // this.editingGroup.otherUsers.push(user);
      var index = this.editingGroup.GroupAssignments.findIndex(
        x => x.userId == user
      );
      this.editingGroup.GroupAssignments.splice(index, 1);
    },
    loadComplexGroupForm() {
      this.complexGroupForm.dialogCreator = true;
      this.complexGroupForm.ruleBuilderTrigger = !this.complexGroupForm
        .ruleBuilderTrigger;
      this.complexGroup.ruleName = null;
      this.complexGroup.ruleDescription = null;
      this.complexGroup.showSaveButton = true;
      this.complexGroup.rules = {
        name: null,
        description: null,
        rule: false,
        operator: "AND",
        id: 1,
        depth: 1,
        group: [],
        lastInGroup: true
      };
    },
    // loadRuleBuilder(
    //   preloadRules,
    //   preloadName = null,
    //   preloadDescription = null
    // ) {
    //   this.complexGroupForm.dialogRuleBuilder = true;
    //   this.complexGroupForm.ruleBuilderTrigger = !this.complexGroupForm
    //     .ruleBuilderTrigger;

    //   if (preloadRules == null) {
    //     this.complexGroup.rules = {
    //       name: null,
    //       description: null,
    //       rule: false,
    //       operator: "AND",
    //       id: 1,
    //       depth: 1,
    //       group: [],
    //       lastInGroup: true
    //     };
    //   } else {
    //     this.complexGroup.rules = preloadRules;
    //     this.complexGroup.ruleName = preloadName;
    //     this.complexGroup.ruleDescription = preloadDescription;
    //   }
    // },
    closeRuleBuilder(rules) {
      console.log(rules);
      this.complexGroupForm.dialogRuleBuilder = false;
      this.complexGroup.showSaveButton = true;

      //If they clicked cancel, we don't overwrite rules
      // if (rules !== null) {
      this.complexGroup.rules = rules;
      // }
    },
    onRulesValueChanged(newVal) {
      if (
        !(
          JSON.stringify(newVal).includes('"group":[]') ||
          JSON.stringify(newVal).includes('"contextColumn":null') ||
          JSON.stringify(newVal).includes('"operator":null') ||
          JSON.stringify(newVal).includes('"value":null') ||
          JSON.stringify(newVal).includes('"value":""')
        )
      ) {
        console.log("Valid rule");
      } else {
        console.log("Invalid rule");
      }
      this.complexGroup.rules = newVal;
    },
    onNameValueChanged(newVal) {
      this.complexGroup.ruleName = newVal;
    },
    onDescriptionValueChanged(newVal) {
      this.complexGroup.ruleDescription = newVal;
    },
    resetComplexGroupForm() {
      if (this.$refs.complexGroupForm) this.$refs.complexGroupForm.reset();
      this.complexGroup.rules = null;
      this.complexGroupForm.dialogCreator = false;
    },
    createComplexGroup() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating your complex group";
      this.loadingMessageLine1 = "This should just take a few seconds!";
      this.loadingMessageLine2 = "";
      var rules = this.complexGroup.rules;
      rules.name = this.complexGroup.groupDisplayName; // this.complexGroup.ruleName;
      rules.description = this.complexGroup.groupDisplayName; //this.complexGroup.ruleDescription;
      var obj = {
        clientId: this.clientId,
        groupName: this.complexGroup.groupDisplayName,
        groupDisplayName: this.complexGroup.groupDisplayName,
        includeSubClients: this.complexGroup.includeSubClients,
        rules: rules
      };
      GroupService.createComplexGroup(obj)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          this.resetComplexGroupForm();
          this.getGroups(true);
        })
        .catch(error => {
          console.log("Error!", error.response);
          this.showLoadingDialog = false;
        });
    },
    loadCommunicationsDialog() {
      this.communicationTrigger = !this.communicationTrigger;
      this.dialogMessageCreator = true;
    },
    closeCommunicationsDialog() {
      this.dialogMessageCreator = false;
    },
    async refreshClientId() {
      this.table.selected = [];
      // this.users = this.allUsers.filter((x) => x.clientId == this.clientId);
      // this.groups = this.allGroups.filter((x) => x.clientId == this.clientId);
      await this.getGroups(true);
      this.getLearningModules();
      this.getMetrics();
    },
    downloadTemplate() {
      //Downloads template file for list group uploads
      GroupService.downloadTemplate();
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    // subClientFlagChanged() {
    //   // This function is for when the user has rules that we estimated the # of participants, but then they change the flag, so we re-estimate
    //   if (
    //     this.complexGroup.rules !== null &&
    //     !this.complexGroupForm.optimizePerformance
    //   ) {
    //     console.log("SUB CLIENT FLAG CHANGED");
    //     if (
    //       !(
    //         JSON.stringify(this.complexGroup.rules).includes('"group":[]') ||
    //         JSON.stringify(this.complexGroup.rules).includes(
    //           '"contextColumn":null'
    //         ) ||
    //         JSON.stringify(this.complexGroup.rules).includes(
    //           '"operator":null'
    //         ) ||
    //         JSON.stringify(this.complexGroup.rules).includes('"value":null') ||
    //         JSON.stringify(this.complexGroup.rules).includes('"value":""')
    //       )
    //     ) {
    //       this.complexGroup.loadingEstimatedParticipants = true;
    //       RulesService.estimateRuleGroup(
    //         [
    //           {
    //             context: "grouprules",
    //             audienceId: null,
    //             includeSubClients: this.complexGroup.includeSubClients
    //           }
    //         ],
    //         this.complexGroup.rules,
    //         this.clientId
    //       )
    //         .then(rules => {
    //           this.complexGroup.estimatedParticipants = rules.filter(
    //             x => x.logic
    //           ).length;
    //           this.complexGroup.loadingEstimatedParticipants = false;
    //         })
    //         .catch(e => {
    //           console.log("Error getting rule estimate", e);
    //           this.complexGroup.loadingEstimatedParticipants = false;
    //         });
    //     }
    //   } else {
    //     this.complexGroup.estimatedParticipants = null;
    //   }
    // },
    closeGroupCreator() {
      this.showCreateSimpleGroup = false;
      this.showCreateQrGroup = false;
      this.showGroupTypeSelector = false;
      // this.getGroupsV2(true);
    },
    refreshGroup(groupId, clientId) {
      this.editingGroup.loadingGroupRefresh = true;
      GroupService.refreshGroupsV2(groupId, clientId)
        .then(response => {
          console.log("Group refresh response ", response);
          this.editingGroup.loadingGroupRefresh = false;
          this.getGroupAssignments(0, true);
        })
        .catch(error => {
          console.log("Error!", error.response);
          this.editingGroup.loadingGroupRefresh = false;
        });
    },
    clearSelected() {
      this.table.selected = [];
    },
    refreshComplexGroupEstimate() {
      this.complexGroup.loadingEstimatedParticipants = true;
      const originalId = this.complexGroup.estimatedParticipantsResponseId;
      RulesService.estimateRuleGroup(
        [
          {
            context: "grouprules",
            audienceId: null,
            includeSubClients: this.complexGroup.includeSubClients
          }
        ],
        this.complexGroup.rules,
        this.clientId
      )
        .then(rules => {
          if (originalId == this.complexGroup.estimatedParticipantsResponseId)
            this.complexGroup.estimatedParticipants = rules.filter(
              x => x.logic
            ).length;
          this.complexGroup.loadingEstimatedParticipants = false;
          this.complexGroup.disableEstimatedParticipants = true;
          console.log("BEGINNING TIMEOUT");
          setTimeout(() => {
            this.complexGroup.disableEstimatedParticipants = false;
          }, 10000);
        })
        .catch(e => {
          console.log("Error getting rule estimate", e);
          this.complexGroup.estimatedParticipants = null;
          this.complexGroup.loadingEstimatedParticipants = false;
          this.complexGroup.disableEstimatedParticipants = true;
          setTimeout(() => {
            this.complexGroup.disableEstimatedParticipants = false;
          }, 5000);
        });
    },
    async loadQrCode(groupId) {
      const clientId = Number(
        this.clientId !== undefined && this.clientId !== null
          ? this.clientId
          : this?.userProfile?.clientId
      );
      this.qr = {
        dialog: true,
        error: null,
        url: null,
        groupId,
        clientId
      };

      // const client =
      //   this.clients.find(x => x.clientId == clientId) ||
      //   this.userProfile?.Client;
      // console.log(clientId, client);
      // if (client?.onboardingTokenPersistent) {
      //   const url = `${window.location.origin}/signup/static/${client.onboardingTokenPersistent}?groupId=${groupId}`;
      //   console.log("Got QR code url", url);
      //   this.qr.dialog = true;
      //   this.qr.url = url;
      // } else {
      //   this.qr.dialog = true;
      //   this.qr.error = "Couldn't find a client with QR code groups set up";
      // }
    },
    showQrCode(type, clientId) {
      let evaluate = false;
      if (type == "List") {
        const client = this.clients.find(x => x.clientId == clientId);
        if (client?.onboardingTokenPersistent) evaluate = true;
      }
      return evaluate;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "postItArray",
      "permissions",
      "globalClientId"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleGroups() {
      // Return chunk of users in array based on page
      // var startingIndex = this.table.options.page - 1 || 0;
      // // if (this.userFilterValue == "uninvited")
      // //   return this.users.filter(
      // //     (x) => !x.onboardingEmailSent //&& x.clientId == this.clientId
      // //   );
      // let itemsPerPage =
      //   (this.table.options && this.table.options.itemsPerPage) ||
      //   this.table.itemsPerPage;
      var startingIndex = this.table.options.page - 1 || 0;
      let itemsPerPage =
        (this.table.options && this.table.options.itemsPerPage) ||
        this.table.itemsPerPage;
      // return this.table.groups;
      return this.table.groups
        .slice()
        .slice(
          startingIndex * itemsPerPage,
          startingIndex * itemsPerPage + itemsPerPage
        )
        .filter(x => x !== undefined);
      // .slice()
      // .slice(
      //   startingIndex * itemsPerPage,
      //   startingIndex * itemsPerPage + itemsPerPage
      // );
    },
    validateRules() {
      if (this.complexGroup.rules !== null) {
        if (
          !(
            JSON.stringify(this.complexGroup.rules).includes('"group":[]') ||
            JSON.stringify(this.complexGroup.rules).includes(
              '"contextColumn":null'
            ) ||
            JSON.stringify(this.complexGroup.rules).includes(
              '"operator":null'
            ) ||
            JSON.stringify(this.complexGroup.rules).includes('"value":null') ||
            JSON.stringify(this.complexGroup.rules).includes('"value":""') ||
            // !this.complexGroup.ruleName ||
            // !this.complexGroup.ruleDescription ||
            !this.complexGroup.groupDisplayName
          )
        ) {
          return true;
        }
      }
      return false;
    },
    computedPageHeader() {
      var title = "";
      if (this.permissions.includes("vue:read:people")) {
        title = title.concat("People");
      }
      if (this.permissions.includes("vue:read:groups")) {
        if (this.permissions.includes("vue:read:people")) {
          title = title.concat(" and ");
        }
        title = title.concat("Groups");
      }
      return title;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    groupColumns() {
      //remove the fields we don't need, to declutter mobile
      //in this case, group type and rules
      return this.isMobile
        ? this.columns.filter(
            x => x.value != "enrollmentRules" && x.value != "groupType"
          )
        : this.columns;
    }
    // usersNotInListGroup() {
    //   if (
    //     this.editingGroup &&
    //     this.editingGroup.groupType == "List" &&
    //     this.editingGroup.GroupAssignments
    //   ) {
    //     return this.users.filter(
    //       (x) =>
    //         x.status != "Deleted" &&
    //         !this.editingGroup.GroupAssignments.some(
    //           (y) => y.User.userId == x.userId
    //         ) &&
    //         !this.editingGroup.selectedUsers.some((y) => y.userId == x.userId)
    //     );
    //   }
    //   return [];
    // },
  },
  watch: {
    "complexGroup.rules": {
      handler() {
        // if (newVal !== null && !this.complexGroupForm.optimizePerformance) {
        //   if (
        //     !(
        //       JSON.stringify(newVal).includes('"group":[]') ||
        //       JSON.stringify(newVal).includes('"contextColumn":null') ||
        //       JSON.stringify(newVal).includes('"operator":null') ||
        //       JSON.stringify(newVal).includes('"value":null') ||
        //       JSON.stringify(newVal).includes('"value":""')
        //     )
        //   ) {
        //     this.complexGroup.loadingEstimatedParticipants = true;
        //     RulesService.estimateRuleGroup(
        //       [
        //         {
        //           context: "grouprules",
        //           audienceId: null,
        //           includeSubClients: this.complexGroup.includeSubClients
        //         }
        //       ],
        //       newVal,
        //       this.clientId
        //     )
        //       .then(rules => {
        //         this.complexGroup.estimatedParticipants = rules.filter(
        //           x => x.logic
        //         ).length;
        //         this.complexGroup.loadingEstimatedParticipants = false;
        //       })
        //       .catch(e => {
        //         console.log("Error getting rule estimate", e);
        //         this.complexGroup.loadingEstimatedParticipants = false;
        //       });
        //   }
        // } else {
        this.complexGroup.estimatedParticipants = null;
        this.complexGroup.estimatedParticipantsResponseId = Date.now();
        // }
      },
      deep: true
    },
    // This watches any options configured for the data table so we know if the user interacts and changes the sorting
    // "table.options": {
    //   handler(newVal, oldVal) {
    //     console.log("New options ", newVal);
    //     console.log("Old options ", oldVal);
    //     // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
    //     var reset = false;
    //     var apiCall = true;

    //     // If none of these exist then we're probably loading the page for the first time so we reset
    //     if (
    //       !oldVal.itemsPerPage ||
    //       !oldVal.page ||
    //       !oldVal.sortBy ||
    //       !oldVal.sortDesc
    //     )
    //       reset = true;
    //     else {
    //       // If any values changed from their old to new states, then we reset because they're changing a sort
    //       if (
    //         newVal.itemsPerPage !== oldVal.itemsPerPage ||
    //         (newVal.sortBy &&
    //           oldVal.sortBy &&
    //           newVal.sortBy[0] !== oldVal.sortBy[0]) ||
    //         (newVal.sortDesc &&
    //           oldVal.sortDesc &&
    //           newVal.sortDesc[0] !== oldVal.sortDesc[0])
    //       )
    //         reset = true;
    //     }

    //     // This means that they just hit the next button. We have to check what the max page we've loaded in is
    //     // We can optimize and load in existing data
    //     // We fetch a page ahead so we want to see if the next page is already loaded
    //     // var maxPageToFetch =
    //     //   newVal.page * newVal.itemsPerPage + newVal.itemsPerPage;
    //     // if (maxPageToFetch > this.table.groups.length)
    //     //   maxPageToFetch = this.table.groups.length;

    //     // console.log(
    //     //   this.table.groups.slice(
    //     //     (newVal.page * newVal.itemsPerPage, maxPageToFetch)
    //     //   )
    //     // );
    //     // if (
    //     //   !reset &&
    //     //   oldVal.page !== newVal.page &&
    //     //   this.table.groups
    //     //     .slice((newVal.page * newVal.itemsPerPage, maxPageToFetch))
    //     //     .findIndex((x) => x == undefined) == -1
    //     //   // this.table.groups.length > (newVal.page - 1) * newVal.itemsPerPage
    //     // ) {
    //     //   apiCall = false;
    //     //   console.log("Not making api call");
    //     //   console.log("Indices", newVal.page * newVal.itemsPerPage);
    //     //   console.log(
    //     //     "Indices",
    //     //     newVal.page * newVal.itemsPerPage + newVal.itemsPerPage
    //     //   );
    //     //   console.log(
    //     //     this.table.groups.slice(
    //     //       (newVal.page * newVal.itemsPerPage,
    //     //       newVal.page * newVal.itemsPerPage + newVal.itemsPerPage)
    //     //     )
    //     //   );
    //     //   console.log(
    //     //     this.table.groups
    //     //       .slice(
    //     //         (newVal.page * newVal.itemsPerPage,
    //     //         newVal.page * newVal.itemsPerPage + newVal.itemsPerPage)
    //     //       )
    //     //       .find((x) => x == undefined)
    //     //   );
    //     // }

    //     if (apiCall) this.getGroupsV2(reset);
    //   },
    //   deep: true,
    // },
    // "table.debounceSearch": debounce(function(newVal) {
    //   this.table.search = newVal;
    //   this.getGroupsV2(true);
    // }, 500),
    // "editingGroup.userTable.options": {
    //   handler(oldVal, newVal) {
    //     // console.log("GROUP ASSIGNMENT TABLE WATCHER");
    //     console.log("New options ", newVal);
    //     console.log("Old options ", oldVal);

    //     let reset = false;
    //     let apiCall = false;

    //     if (
    //       !oldVal.itemsPerPage ||
    //       !oldVal.page ||
    //       !oldVal.sortBy ||
    //       !oldVal.sortDesc
    //     ) {
    //       reset = true;
    //       apiCall = true;
    //       this.editingGroup.userTable.options = newVal;
    //     } else {
    //       // If any values changed from their old to new states, then we reset because they're changing a sort
    //       if (
    //         newVal.itemsPerPage !== oldVal.itemsPerPage ||
    //         (newVal.sortBy &&
    //           oldVal.sortBy &&
    //           newVal.sortBy[0] !== oldVal.sortBy[0]) ||
    //         (newVal.sortDesc &&
    //           oldVal.sortDesc &&
    //           newVal.sortDesc[0] !== oldVal.sortDesc[0])
    //       ) {
    //         reset = true;
    //         apiCall = true;
    //       }
    //     }

    //     if (apiCall) {
    //       // console.log("WATCHER GETTING GROUP ASSIGNMENTS");
    //       this.getGroupAssignments(reset);
    //     }
    //   },
    //   deep: true,
    // },
    "userTable.debounceSearch": debounce(function(newVal) {
      this.userTable.search = newVal;
      // this.getUsersV2(true);
    }, 500),
    // "userTable.options": {
    //   handler(newVal, oldVal) {
    //     console.log("New options ", newVal);
    //     console.log("Old options ", oldVal);
    //     // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
    //     var reset = false;
    //     var apiCall = true;
    //     // If none of these exist then we're probably loading the page for the first time so we reset
    //     if (
    //       !oldVal.itemsPerPage ||
    //       !oldVal.page ||
    //       !oldVal.sortBy ||
    //       !oldVal.sortDesc
    //     )
    //       reset = true;
    //     else {
    //       // If any values changed from their old to new states, then we reset because they're changing a sort
    //       if (
    //         newVal.itemsPerPage !== oldVal.itemsPerPage ||
    //         (newVal.sortBy &&
    //           oldVal.sortBy &&
    //           newVal.sortBy[0] !== oldVal.sortBy[0]) ||
    //         (newVal.sortDesc &&
    //           oldVal.sortDesc &&
    //           newVal.sortDesc[0] !== oldVal.sortDesc[0])
    //       )
    //         reset = true;
    //     }
    //     // This means that they just hit the next button. We have to check what the max page we've loaded in is
    //     // We can optimize and load in existing data
    //     if (
    //       !reset &&
    //       oldVal.page !== newVal.page &&
    //       this.userTable.users.filter(x => x !== undefined).length >
    //         (newVal.page - 1) * newVal.itemsPerPage
    //     ) {
    //       console.log("We're not going to make the API call");
    //       apiCall = false;
    //     }
    //     if (apiCall) this.getUsersV2(reset);
    //   },
    //   deep: true
    // },
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        if (
          !reset &&
          oldVal.page !== newVal.page &&
          this.table.groups.filter(x => x !== undefined).length >
            (newVal.page - 1) * newVal.itemsPerPage
        ) {
          console.log("We're not going to make the API call");
          apiCall = false;
        }
        if (apiCall) this.getGroups(reset);
      },
      deep: true
    },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getGroups(true);
    }, 500),
    clientId: {
      async handler(newVal) {
        console.log("New clientId", newVal);
        this.table.selected = [];
        // this.users = this.allUsers.filter((x) => x.clientId == this.clientId);
        // this.groups = this.allGroups.filter((x) => x.clientId == this.clientId);
        await this.getGroups(true);
        this.getLearningModules();
        this.getMetrics();
      }
    }
  }
};
</script>

<style scoped>
.people-button .v-btn--active.no-active::before {
  opacity: 0 !important;
}

.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}
.group-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.group-table >>> .v-data-table-header {
  background-color: white;
}
</style>
