<template>
    <v-card flat>
      <v-toolbar dark color="brand" rounded="0">
        <v-btn dark icon @click="closeWindow">
          <v-icon large>mdi-close-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>New Group</v-toolbar-title>
        <v-spacer></v-spacer>
       
      </v-toolbar>
      <v-divider />
      <v-card-text>
        
        <v-text-field 
        v-model="groupName" 
        label="Group Name" 
        outlined
        ></v-text-field>
       
       
      </v-card-text>
  
      
      <v-card-actions class="pb-8 d-flex justify-center">
        <v-btn
          color="brandCyan"
          class="white--text"
          :outlined="!groupName"
          depressed
          rounded
          width="190"
          @click="createListGroup"
          :disabled="!groupName"
        >
          Add new group
        </v-btn>
      </v-card-actions>
      <!-- Dialog used for displaying loading message. -->
      <LoadingDialog
        :showDialog="showLoadingDialog"
        :header="loadingHeaderMessage"
        :line1="loadingMessageLine1"
        :line2="loadingMessageLine2"
      ></LoadingDialog>
    </v-card>
  </template>
  
  <script>
  import GroupService from "../../services/GroupService";
  // import RulesService from "../services/RulesService";
  // import LearningService from "../services/LearningService";
  // import ProgramService from "../services/ProgramService";
  import LoadingDialog from "@/components/loadingDialog.vue";
  // import RuleBuilder from "@/components/RuleBuilderV3.vue";
  import { mapState } from "vuex";
  
  import Lottie from "vue-lottie/src/lottie.vue";
  import * as animationData from "@/assets/robin/Eating.json";
  import UserService from "../../services/UserService";
  
  export default {
    name: "GroupCreatorWidget",
    components: {
      LoadingDialog,
      // RuleBuilder,
      //Lottie
    },
    props: { clientId: Number, source: String, version: Number },
    data() {
      return {
        lottieOptions: {
          animationData: animationData.default,
          loop: true,
          autoplay: true
        },
        anim: null,
        showLoadingDialog: false,
        loadingHeaderMessage: null,
        loadingMessageLine1: null,
        loadingMessageLine2: null,
        groupName: null,
        hasSubClients: false,
        includeSubClients: false,
        selectedUserColumn: null,
        displaySimpleGroupResults: false,
        showRobinAnimation: false,
        showAttributeTable: false,
        columnValues: [],
        metrics: [],
        modules: [],
        complexGroupForm: {
          valid: false,
          dialogRuleBuilder: false,
          ruleBuilderTrigger: false
        },
        complexGroup: {
          groupDisplayName: null,
          includeSubClients: false,
          rules: null,
          ruleName: null,
          ruleDescription: null,
          estimatedParticipants: 0
        },
        attributesForNewGroups: []
      };
    },
    created() {
      // this.getLearningModules();
      // this.getMetrics();
      if (!this.magicLinkToken)
        UserService.getClientsByClient(this.clientId)
          .then(response => {
            if (response.length > 0) {
              this.hasSubClients = true;
            }
          })
          .catch(error => {
            console.log("Error getting subclients", error.response || error);
          });
    },
    mounted() {},
    methods: {
      handleAnimation: function(anim) {
        this.anim = anim;
      },
      
      async createListGroup() {
        this.showLoadingDialog = true;
        this.loadingHeaderMessage = "Creating Simple Group";
        this.loadingMessageLine1 = "Doing the complex part so you don't have to.";
        this.loadingMessageLine2 = "This should just take a minute.";
        console.log(
          "Creating list groups"
        );
          let name =
            this.groupName;
           
          let body = [{
            clientId: this.clientId,
            status: "Active",
            groupName: name,
            groupDisplayName: name,
            displayName: name,
            groupType: "List"
          }];
        
  
        // Moved to v2 of API. Need to update this for all uses of this widget
        // if (this.version == 3) {
        try {
          const response = await GroupService.createGroupV2(
            body,
            "list",
            {},
            this.magicLinkToken
          );
          console.log("Group res ", response);
          let id = response?.result?.[0]?.groupId;
          this.getGroups(id);

          // this.getGroups(response?.result?.[0]);
        } catch (e) {
          console.log("Error creating list group", e);
        } finally {
          this.showLoadingDialog = false;
          this.closeWindow();
        }
        return;
     
      },
  
      getGroups(prop) {
        this.$emit("get-groups", prop);
      },
      closeWindow() {
  
       this.groupName = null;
        this.$emit("close");
      }
     
      
    },
    computed: {
      ...mapState(["userProfile", "clients", "magicLinkToken"])
    },
      
   
  };
  </script>

  